import React, { useState, useEffect } from "react";

const CookiePopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = document.cookie.split('; ').find(row => row.startsWith('cookieConsent='));
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        document.cookie = "cookieConsent=true; path=/; max-age=31536000"; // 1 year
        setIsVisible(false);
    };

    // const handleClear = () => {
    //     document.cookie = "cookieConsent=; path=/; max-age=0"; // Clear the cookie
    //     setIsVisible(true);
    // };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="cookie-popup">
            <div className="cookie-popup-content">
                <p>Мы используем файлы cookie, чтобы улучшить ваше взаимодействие с сайтом. Продолжая использовать сайт, вы соглашаетесь с <strong><a href="/policy.pdf" target="_blank">Политикой конфиденциальности</a></strong> и <strong><a href="/agreement.pdf" target="_blank">Пользовательским соглашением</a></strong>.</p>
                <button className="cookie-popup-btn" onClick={handleAccept}>Понятно</button>
            </div>
        </div>
    );
};

export default CookiePopup;