import React, {useEffect} from "react";
import CookiePopup from "./CookiePopup";

const Footer = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `((r,p)=>{const l=document.getElementById(r);l.contentWindow.document.open(),l.contentWindow.document.write(decodeURIComponent(escape(atob(p)))),l.contentWindow.document.close()})("medium_dark_70000001054360186", "PGhlYWQ+PHNjcmlwdCB0eXBlPSJ0ZXh0L2phdmFzY3JpcHQiPgogICAgd2luZG93Ll9fc2l6ZV9fPSdtZWRpdW0nOwogICAgd2luZG93Ll9fdGhlbWVfXz0nZGFyayc7CiAgICB3aW5kb3cuX19icmFuY2hJZF9fPSc3MDAwMDAwMTA1NDM2MDE4NicKICAgIHdpbmRvdy5fX29yZ0lkX189JycKICAgPC9zY3JpcHQ+PHNjcmlwdCBjcm9zc29yaWdpbj0iYW5vbnltb3VzIiB0eXBlPSJtb2R1bGUiIHNyYz0iaHR0cHM6Ly9kaXNrLjJnaXMuY29tL3dpZGdldC1jb25zdHJ1Y3Rvci9hc3NldHMvaWZyYW1lLmpzIj48L3NjcmlwdD48bGluayByZWw9Im1vZHVsZXByZWxvYWQiIGNyb3Nzb3JpZ2luPSJhbm9ueW1vdXMiIGhyZWY9Imh0dHBzOi8vZGlzay4yZ2lzLmNvbS93aWRnZXQtY29uc3RydWN0b3IvYXNzZXRzL2RlZmF1bHRzLmpzIj48bGluayByZWw9InN0eWxlc2hlZXQiIGNyb3Nzb3JpZ2luPSJhbm9ueW1vdXMiIGhyZWY9Imh0dHBzOi8vZGlzay4yZ2lzLmNvbS93aWRnZXQtY29uc3RydWN0b3IvYXNzZXRzL2RlZmF1bHRzLmNzcyI+PC9oZWFkPjxib2R5PjxkaXYgaWQ9ImlmcmFtZSI+PC9kaXY+PC9ib2R5Pg==")`;
        document.body.appendChild(script);
    }, []);

    return (
        <>
            <footer className="foot">
                <div className="footer-content">
                    <div className="footer-logo">
                        <img src="./img/logo.svg" />
                    </div>
                    <nav className="footer-nav">
                        <a href="#about-link">О нас</a>
                        <a href="#all_services">Услуги и цены</a>
                        <a href="#masters-link">Наши мастера</a>
                        {/* <a href="#tools-link">Оборудованиe</a> */}
                        <a href="#contacts-link">Адреса студий</a>
                    </nav>
                    <div className="footer-socials">
                        <p><small>Ищите нас в социальных сетях:</small></p>
                        <div className="socials-icons">
                            <div className="icon">
                                <a href="https://www.instagram.com/cryon__studio" target="_blank">
                                    <img src="./img/icons/instagram.svg" />
                                </a>
                            </div>
                            <div className="icon">
                                <a href="https://wa.me/79956556564?text=%D0%9D%D0%B0%D1%88%D0%BB%D0%B0%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8E%20%D0%BD%D0%B0%20%D0%B2%D0%B0%D1%88%D0%B5%D0%BC%20%D1%81%D0%B0%D0%B9%D1%82%D0%B5%2C%20%D1%83%D1%82%D0%BE%D1%87%D0%BD%D0%B8%D1%82%D0%B5%20%D0%BF%D0%BE%D0%B6%D0%B0%D0%BB%D1%83%D0%B9%D1%81%D1%82%D0%B0%20-%20" target="_blank">
                                    <img src="./img/icons/whatsapp.svg" />
                                </a>
                            </div>
                            <div className="icon telegram">
                                <a href="https://vk.com/cryonstudio" target="_blank">
                                    <img src="./img/icons/vk.svg" />
                                </a>
                            </div>
                            <div className="icon telegram">
                                <a href="https://www.youtube.com/@cryon_studio" target="_blank">
                                    <img src="./img/icons/youtube.svg" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-address">
                        <p>ул. Старопетровский пр. дом 8</p>
                        <p>м. Войковская</p>
                        <a href="tel:+7 (495) 157-65-65"><p>+7 (495) 157-65-65</p></a>
                        <p>Пн.-Вс. с 10:00 - 22:00</p>
                    </div>
                </div>
                <div className="disclaimer">Работаем только по предварительной записи!</div>


                <div className="yandex-badges">
                <iframe src="https://yandex.ru/sprav/widget/rating-badge/242483318465?type=award&theme=dark" width="150" height="50" frameborder="0"></iframe>
                    {/* <iframe src="https://yandex.ru/sprav/widget/rating-badge/242483318465?type=alt&theme=dark" width="150" height="50" frameborder="0"></iframe> */}
                    <iframe id="medium_dark_70000001054360186" frameBorder="0" width="150px" height="50px" sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
                </div>

                <div className="policy-links">
                <a href="/policy.pdf" target="_blank" >Политика конфиденциальности</a>
                <a href="/agreement.pdf" target="_blank">Пользовательское соглашение</a>
                </div>

                <CookiePopup />

            </footer>
        </>
    );
};

export default Footer;